<template>
  <a href="#" class="text-primary" @click.prevent="onClick">
    <div class="h3"><font-awesome-icon :icon="['far', 'handshake']" /></div>
  </a>
</template>

<script>
export default {
  name: "ProductsTableActions",

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    onClick() {
      this.$store.commit("incidents/editSetIncidentDetail", this.data);
      this.$store.commit("incidents/editShow");
    },
  },
};
</script>
