<template>
  <div class="ui-orders__wrapper-2">
    <ViewOverview v-if="isView('overview')" />
    <ViewDdmp v-if="isView('ddmp')" />
    <ViewWh v-if="isView('wh')" />
    <ViewDdmi v-if="isView('ddmi')" />
    <ViewOrders v-if="isView('orders')" />
    <ViewIncident v-if="isView('incident')" />
  </div>
</template>

<script>
import ViewOverview from "./overview/View";
import ViewDdmp from "./ddmp/View";
import ViewWh from "./wh/View";
import ViewDdmi from "./ddmi/View";
import ViewOrders from "./orders/View";
import ViewIncident from "./incident/View";

export default {
  name: "OrdersRoundView",
  components: {
    ViewOverview,
    ViewDdmp,
    ViewWh,
    ViewDdmi,
    ViewOrders,
    ViewIncident,
  },

  methods: {
    isView(view) {
      return this.$store.state.rounds.view === view;
    },
  },
};
</script>
