<template>
  <strong>{{ getPrice() }} </strong>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice } from "../../../../../../helpers/common";

export default {
  name: "ProductsTablePrice",

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getPrice() {
      const price = get(this.data, "tot_price", 0);
      return formatPrice(price, this.currencySymbol).format();
    },
  },
};
</script>
