<template>
  <div class="d-inline-block">
    <a href="#0" @click="onClick"
      ><font-awesome-icon icon="map-marker-alt" /> {{ title }}</a
    >

    <CModal
      size="xl"
      :title="title"
      :show.sync="show"
      :closeOnBackdrop="false"
      :centered="true"
    >
      <div style="height: 500px; width: 100%" v-if="show">
        <l-map
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          v-if="center"
        >
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-marker :lat-lng="marker.latLng" v-if="marker">
            <l-popup>
              <div>
                {{ markerInfo(marker) }}
              </div>
            </l-popup>
          </l-marker>
        </l-map>
      </div>
      <template #footer>
        <CButton color="primary" variant="outline" @click="onClose"
          >Chiudi</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";

export default {
  name: "OrdersLocationMap",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },

  props: {
    title: { type: String, default: "" },
    position: { type: Object, required: true },
  },

  data() {
    return {
      show: false,
      zoom: 13,
      center: null,
      mapOptions: { zoomSnap: 0.5 },
      marker: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
    };
  },

  watch: {
    position() {
      this.setup();
    },
  },

  mounted() {
    this.setup();
  },

  methods: {
    setup() {
      const latLng = this.getLatLng(this.position);
      if (latLng) {
        this.marker = latLng ? { ...this.position, latLng } : null;
        this.center = latLng ? latLng : null;
      }
    },

    getLatLng(position) {
      const { lat, long } = position;
      if (lat && long) {
        return latLng(lat, long);
      }
      return null;
    },

    markerInfo(position) {
      const { address, postal_code, city, country } = position;
      let info = [];
      if (address) {
        info.push(address);
      }
      if (postal_code) {
        info.push(`${postal_code},`);
      }
      if (city) {
        info.push(city);
      }
      if (country) {
        info.push(`(${country})`);
      }
      return info.join(" ");
    },

    onClick() {
      // open modal only if coordinates are available
      if (this.getLatLng(this.position)) {
        this.show = true;
      }
    },

    onClose() {
      this.show = false;
    },
  },
};
</script>
