<template>
  <div>
    <div class="ui-type-caption text-uppercase text-gray-600">
      #{{ getOrderId() }} - {{ getOrderTotPrice() }}
    </div>
    <span>{{ getFamilyName() }}</span>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice } from "../../../../../../helpers/common";

export default {
  name: "ProductsTableOrders",

  data() {
    return {
      data: {},
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getOrderId() {
      return get(this.data, "order.id");
    },

    getOrderTotPrice() {
      const totPrice = get(this.data, "order.view.tot_price", 0);
      return formatPrice(totPrice, this.currencySymbol).format();
    },

    getFamilyName() {
      return get(this.data, "order.family.name");
    },
  },
};
</script>
