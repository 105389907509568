<template>
  <div class="ui-orders-legend">
    <div v-for="item in statusMap" :key="item.id">
      <span
        class="badge badge-bubble-sm badge-pill bg-orders-cyan mr-1"
        :class="`bg-orders-${item.color}`"
      />
      <small>{{ item.label }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersLegend",

  props: {
    statusMap: {
      type: Array,
      required: true,
    },
  },
};
</script>
