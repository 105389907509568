<template>
  <div>{{ getConseration() }}</div>
</template>

<script>
import { mapState } from "vuex";
import get from "lodash/get";
import { getOptionLabel } from "../../../../../../helpers/common";

export default {
  name: "ProductsTableConservation",

  data() {
    return {
      data: {},
    };
  },

  computed: {
    ...mapState("rounds", ["filters"]),
  },

  methods: {
    getConseration() {
      const ptype = get(this.data, "ppm.lot.productproducer.ptype");
      return getOptionLabel(ptype, this.filters.options.productType);
    },
  },
};
</script>
