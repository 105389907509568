<template>
  <strong>{{ getPrice() }} </strong>
</template>

<script>
import { mapGetters } from "vuex";
import { formatPrice } from "../../../../../../helpers/common";

export default {
  name: "ProductsTablePrice",

  data() {
    return {
      data: {},
    };
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getPrice() {
      return formatPrice(this.data.price, this.currencySymbol).format();
    },
  },
};
</script>
