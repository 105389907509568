<template>
  <span v-if="haveIncidents()" :class="colorClass">
    (
    <span class="mr-1">{{ total }}</span>
    <font-awesome-icon icon="exclamation-triangle" />
    )
  </span>
</template>

<script>
export default {
  name: "OrderIncidentBadge",

  props: {
    total: {
      type: Number,
      required: true,
    },
    open: {
      type: Number,
      required: true,
    },
  },

  computed: {
    colorClass() {
      return this.open > 0 ? `text-warning` : `text-black-50`;
    },
  },

  methods: {
    haveIncidents() {
      return this.total > 0;
    },
  },
};
</script>
\
